<template>
  <div>
    <b-row class="form-box">
      <b-col
        :md="item.md || 6"
        class="my-1"
        v-for="(item, index) in initData.information"
        :key="index"
      >
        <!-- 左右结构 -->
        <template v-if="item.type == null && item.text == null">
          <b-row v-for="(x, y) in item" :key="y">
            <div class="my-1 col">
              <b-form-group
                :label="x.text"
                :id="x.id"
                :label-for="'form-' + index + y"
                class="search-flex"
                v-bind:class="[
                  { required: x.required },
                  x.class,
                  {
                    form_error:
                      x.required &&
                      formError &&
                      (x.model == null || x.model == '')
                  }
                ]"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <!-- input -->
                <template v-if="x.type == 'input'">
                  <b-input-group :class="{ no_r: !x.icon }">
                    <template v-slot:append>
                      <b-input-group-text v-if="x.icon">
                        <b-icon :icon="x.icon"></b-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="x.model"
                      :type="x.input_type || 'text'"
                      :min="x.min"
                      :max="x.max"
                      :placeholder="
                        x.disabled
                          ? ''
                          : x.placeholder || $t('page.please_choose_input')
                      "
                      :disabled="x.disabled"
                    ></b-form-input>
                  </b-input-group>
                </template>
                <!-- 下拉框 -->
                <template v-else-if="x.type == 'select'">
                  <SelectLoading
                    v-if="x.options == 0"
                    :size="0.7"
                  ></SelectLoading>
                  <sv-select
                    v-model="x.model"
                    :disabled="x.options == 0 || x.disabled"
                    :placeholder="
                      x.disabled
                        ? ''
                        : x.placeholder || $t('page.please_choose')
                    "
                    :options="x.options"
                    :reduce="options => options.value"
                    :multiple="x.multiple"
                    :selectable="options => !options.disabled"
                    label="label"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                      v-if="option.index"
                    >
                      <div class="d-center" :class="'level_' + option.index">
                        <span v-if="option.index == 2">--</span>
                        {{ option.label }}
                      </div>
                    </template>
                  </sv-select>
                </template>
                <!-- img -->
                <template v-if="x.type == 'img'">
                  <div>
                    <img
                      @click="showImg(x.url)"
                      v-if="x.url != null && x.url != ''"
                      class="image pointer"
                      :src="x.url"
                      alt="image"
                    />
                    <img
                      v-else
                      alt="edit"
                      class="image pointer"
                      src="@/assets/media/users/default.png"
                    />
                  </div>
                </template>
                <!-- 上传 -->
                <template v-if="x.type == 'upload'">
                  <div class="upload">
                    <div class="exhibit">
                      {{ x.exhibit_text }}
                    </div>
                    <div class="exhibit-botton">
                      <b-form-file
                        v-if="x.uploadShow"
                        v-model="x.model"
                        :disabled="x.state == 'uploading'"
                        :accept="x.accept"
                        placeholder="Upload"
                        class="exhibit-input"
                        @input="x.input($event, x)"
                      >
                        <template slot="file-name" slot-scope="{}">
                          <div>
                            Upload
                            <b-icon
                              v-if="x.state == 'uploading'"
                              class="counterclockwise"
                              icon="arrow-counterclockwise"
                              animation="spin-reverse"
                              font-scale="1.2"
                            >
                            </b-icon>
                          </div>
                        </template>
                      </b-form-file>
                      <div
                        v-if="x.checkShow"
                        class="exhibit-input2 mb-2"
                        @click="x.check"
                      >
                        View
                      </div>
                      <button
                        v-if="x.deleteShow"
                        class="exhibit-input-delete"
                        :disabled="x.state == 'uploading'"
                        @click="x.uploadDelete(x)"
                      >
                        {{ x.state == 'uploading' ? 'Deleting' : 'Delete' }}
                      </button>
                      <div class="mt-4 ifrefundable" v-if="x.deleteShow">
                        <b-form-group
                          label="VAT Refund?"
                          label-cols-sm="5"
                          label-cols-lg="5"
                          class="isRefundable"
                        >
                          <b-form-checkbox
                            v-b-popover.hover.top="
                              x.ifrefundable || disabled
                                ? disabled
                                  ? ''
                                  : 'YES'
                                : 'NO'
                            "
                            @change="isRefundable($event, x)"
                            switch
                            size="lg"
                            :disabled="disabled || x.delete.disabled"
                            v-model="x.ifrefundable"
                          ></b-form-checkbox>
                        </b-form-group>
                        <span> {{ x.delete.text }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </b-form-group>
            </div>
          </b-row>
        </template>
        <b-form-group
          :label="item.text"
          :id="item.id"
          :label-for="'form-' + index"
          class="search-flex"
          v-bind:class="[{ required: item.required }, item.class]"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <!-- input -->
          <template v-if="item.type == 'input'">
            <b-input-group :class="{ no_r: !item.icon }">
              <template v-slot:append>
                <b-input-group-text v-if="item.icon">
                  <b-icon :icon="item.icon"></b-icon>
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="item.model"
                :type="item.input_type || 'text'"
                :placeholder="
                  item.disabled
                    ? ''
                    : item.placeholder || $t('page.please_choose_input')
                "
                :disabled="item.disabled"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 纯文字 -->
          <template v-else-if="item.type == 'text'">
            <div :class="item.text_class">
              {{ item.display_text }}
            </div>
          </template>
          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading
              v-if="item.options == 0 && !item.hideLoading"
              :size="0.7"
            ></SelectLoading>
            <sv-select
              v-if="item.onSearch"
              v-model="item.model"
              :disabled="
                (item.options == 0 || item.disabled) && !item.hideLoading
              "
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.disabled"
              :filter="ajaxFilter"
              @input="item.input($event)"
              @search="item.onSearch"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
            <sv-select
              v-if="!item.onSearch"
              v-model="item.model"
              :disabled="item.options == 0 || item.disabled"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.disabled"
              @input="item.input($event)"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>
          <!-- 时间组件-->
          <template v-else-if="item.type == 'date'">
            <b-input-group>
              <template v-slot:append>
                <div
                  v-if="
                    !item.disabled && item.model != null && item.model != ''
                  "
                  class="datepicker-x"
                  @click="initialTime(item, 'model')"
                >
                  <b-icon-x font-scale="1.45"></b-icon-x>
                </div>
                <b-input-group-text>
                  <b-icon-calendar2-date></b-icon-calendar2-date>
                </b-input-group-text>
              </template>
              <b-form-datepicker
                v-model="item.model"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                :hide-header="true"
                :placeholder="item.disabled ? '' : item.placeholder"
                :disabled="item.disabled"
                locale="de"
              ></b-form-datepicker>
            </b-input-group>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <InvoiceDocument
      :initData="initData.invoice_document"
      :formError.sync="formError"
    ></InvoiceDocument> -->
    <ArticleInformation
      :initData="initData.article_information"
      :formError.sync="formError"
    ></ArticleInformation>
    <LogisticsInformation
      :initData="initData.logistics_information"
      :options="options"
      :formError.sync="formError"
      :initDataFn="initDataFn"
    ></LogisticsInformation>
    <!-- Object.keys(initData.settlement_information).length -->
    <SettlementInformation
      v-if="
        initData.settlement_information &&
          Object.keys(initData.settlement_information).length > 0
      "
      :initData="initData.settlement_information"
    ></SettlementInformation>
    <Purchaser :initData="initData.purchaser"></Purchaser>
  </div>
</template>

<script>
import SelectLoading from '@/components/Loading/Index';
// import InvoiceDocument from '@/views/content/Detail/PurchaseoOverview/Information/InvoiceDocument.vue';
import ArticleInformation from '@/views/content/Detail/PurchaseoOverview/Information/ArticleInformation.vue';
import LogisticsInformation from '@/views/content/Detail/PurchaseoOverview/Information/LogisticsInformation.vue';
import Purchaser from '@/views/content/Detail/PurchaseoOverview/Information/Purchaser';
import SettlementInformation from '@/views/content/Detail/PurchaseoOverview/Information/SettlementInformation.vue';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'PurchaseoOverviewInformation',

  data() {
    return {
      id: null,
      disabled: false
    };
  },
  props: {
    initData: Object,
    options: Object,
    formError: Boolean,
    initDataFn: Function
    // id: String
  },
  inheritAttrs: false,
  components: {
    // InvoiceDocument,
    ArticleInformation,
    LogisticsInformation,
    Purchaser,
    SettlementInformation,
    SelectLoading
  },
  methods: {
    init() {
      let { purchaseId } = this.$route.params;
      this.id = purchaseId;
      // this.selected = ifrefundable
      console.log(this.initData);
    },
    ajaxFilter(options) {
      return options;
    },
    isRefundable(e, item) {
      console.log(e);
      this.disabled = true;
      var refundable = 2;
      if (e) {
        refundable = 1;
      }
      apiBus.purchaseInvoice
        .savePurchaseInvoiceRefundable({
          purchase_overview_id: this.id,
          refundable: refundable
        })
        .then(data => {
          console.log(data);
          this.disabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          console.log(error);
          this.disabled = false;
          item.ifrefundable = !item.ifrefundable;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    }
  },

  computed: {
    classObject() {
      return {};
    }
  },
  mounted() {
    this.init();
  },
  watch: {}
};
</script>
<style scoped></style>
